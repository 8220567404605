.session
{
    width: 100%;
}

.vote-buttons
{
    display: flex;
    justify-content: space-around;
}

.vote-buttons i
{
    margin: 0;
    padding: 16px;
    border: 8px solid;
    border-radius: 50%;
}

.i.red
{
    border-color: #db2828;
}

.i.green
{
    border-color: #21ba45;
}

.score
{
    position: absolute;
    top: 32px;
    left: 0;
    right: 0;
    font-size: 32px;
    text-align: center;
    color: white;
}
.content
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.pointer
{
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.start
{
    border-radius: 50%;
    border-color: #21ba45;
    border-width: 8px;
    border-style: solid;
    padding: 12px;
    font-size: 72px;
    color: #21ba45;
}

.end
{
    position: absolute;
    bottom: 32px;

    border-radius: 50%;
    border-color: white;
    border-width: 8px;
    border-style: solid;
    padding: 12px;
    font-size: 32px;
    color: white;
}
